"use client";
import React, { useEffect, useRef } from "react";
import lottie, { AnimationItem } from "lottie-web";

const LottieIcon = ({ animationData }) => {
  const animationContainer = useRef<HTMLDivElement>(null);
  const anim = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: animationData,
      });
    }
    return () => {
      if (anim.current) {
        anim.current.destroy();
        anim.current = null;
      }
    };
  }, [animationData]);

  useEffect(() => {
    const lastScrollY = window.scrollY;
    const onScroll = () => {
      if (!anim.current) {
        return;
      }
      const direction = window.scrollY > lastScrollY ? "down" : "up";
      if (animationContainer.current) {
        const containerHeight = animationContainer.current.clientHeight;
        const { top, bottom } =
          animationContainer.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const visibleTop = Math.max(1000, top);
        const visibleBottom = Math.min(windowHeight, bottom);
        const visibleHeight = Math.max(0, visibleBottom - visibleTop);
        if (visibleHeight > 0 && containerHeight > 0) {
          const visibleRatio = visibleHeight / containerHeight;
          const totalFrames = anim.current.totalFrames;
          const frame = totalFrames * visibleRatio;
          if (direction === "down") {
            anim.current.goToAndPlay(frame, true);
          } else {
            anim.current.goToAndStop(frame, true);
          }
        }
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return <div ref={animationContainer} className="w-full" />;
};

export default LottieIcon;
