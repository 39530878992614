"use client";
import { useEffect, useState } from "react";
import { ArrowUp } from "~/components/icons";
import { cn } from "~/lib/utils";

export const ScrollToTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div className="fixed right-5 top-5 z-50 hidden lg:block">
      <button
        type="button"
        onClick={scrollToTop}
        aria-label="Scroll To Top"
        className={cn(
          isVisible ? "opacity-100" : "opacity-0",
          "flex flex-col items-center gap-1 rounded-lg border border-black bg-white px-1 py-3 text-black transition-opacity hover:bg-neutral-50",
        )}
      >
        <p className="text-[12px] font-light">TOP</p>
        <ArrowUp size={30} color="black" />
      </button>
    </div>
  );
};
