"use client";
import React from "react";
import AspectRatioComponent from "./aspect-ratio";
import HeaderText from "./reusables/header-text";
import Image from "next/image";

import OutlineButton from "~/components/reusables/outline-button";

const SubscribeCard = ({ subscribeCard, size = "lg" }) => {
  return (
    <>
      {size == "lg" ? (
        <div className="relative z-10 h-[500px] overflow-hidden rounded bg-black px-3 py-8 text-white md:h-[700px] md:rounded-lg md:px-5 md:py-[60px]">
          <Image
            unoptimized
            src="/images/graphics/middle-diamond.png"
            alt="Another Image"
            width="0"
            height="0"
            className="absolute bottom-[-8px] right-[-7px] z-[11] hidden w-[16px] lg:block"
          />
          <div className="absolute bottom-[-45px] right-0 z-10 hidden h-[50px] w-[2px] bg-black lg:block"></div>
          <div className="absolute bottom-0 right-[-45px] z-10 hidden h-[2px] w-[50px] bg-black lg:block"></div>
          <div className="absolute bottom-5 left-0 h-1/2 w-full">
            <AspectRatioComponent aspectRatio={{ width: 508, height: 423 }}>
              <Image
                unoptimized
                src={subscribeCard?.background.data[0]?.attributes.url}
                fill
                alt="Subscribe card background"
                className="absolute bottom-0 left-0 right-0"
              />
            </AspectRatioComponent>
          </div>
          <div className="relative ml-10 mt-10">
            <div className="absolute left-[110px] top-[-60px] h-[150px] w-[150px] animate-spin-slow md:left-[230px]">
              <AspectRatioComponent aspectRatio={{ width: 190, height: 190 }}>
                <Image
                  unoptimized
                  src="/images/pages/home/subscribe-logo.svg"
                  fill
                  alt="Subscribe"
                  className="max-w-[360px]"
                />
              </AspectRatioComponent>
            </div>
            <HeaderText
              inputString={subscribeCard?.header[0].fullText}
              className="font-h2 relative ml-2 max-w-[200px] bg-black md:max-w-[285px] "
            />
            <p className="font-h4 mt-10 w-full md:w-[70%]">
              {subscribeCard.subheader}
            </p>
          </div>
          <div className="absolute bottom-10 left-[60px] mt-[200px]">
            <OutlineButton variant={"dark"}>
              <a
                href={subscribeCard?.button[0].url || ""}
                className="font-btn-lg flex h-full  w-full items-center  justify-center px-3 py-2"
              >
                {subscribeCard?.button[0].cta}
              </a>
            </OutlineButton>
          </div>
        </div>
      ) : size === "blog" ? (
        <div className="relative z-10 flex min-h-[460px] flex-col justify-between overflow-hidden rounded bg-black px-4 py-8 text-white md:rounded-lg md:px-6 md:py-[60px]">
          <div className="absolute bottom-5 left-0 z-0 h-1/2 w-full">
            <AspectRatioComponent aspectRatio={{ width: 508, height: 423 }}>
              <Image
                unoptimized
                src={subscribeCard?.background.data[0]?.attributes.url}
                fill
                alt="Subscribe card background"
                className="absolute bottom-[20px] left-0 right-0"
              />
            </AspectRatioComponent>
          </div>
          <div className="relative z-10 mt-2">
            <div className="absolute left-[20px] top-[-30px] h-[100px] w-[100px] animate-spin-slow md:left-[110px]">
              <AspectRatioComponent aspectRatio={{ width: 190, height: 190 }}>
                <Image
                  unoptimized
                  src="/images/pages/home/subscribe-logo.svg"
                  fill
                  alt="Subscribe"
                  className="max-w-[360px]"
                />
              </AspectRatioComponent>
            </div>
            <HeaderText
              inputString={subscribeCard.header[0].fullText}
              className="font-h4 relative max-w-[160px] bg-black md:max-w-[180px] "
            />
            <h5 className="mb-10 mt-6 w-full text-[16px] font-light md:w-[90%]">
              {subscribeCard.subheader}
            </h5>
          </div>
          <div className="relative z-10 w-full">
            <OutlineButton variant={"dark"}>
              <a
                href={subscribeCard.button[0].url || ""}
                className="font-btn-lg px-3 text-[14px]"
              >
                {subscribeCard.button[0].cta}
              </a>
            </OutlineButton>
          </div>
        </div>
      ) : (
        <div className="relative z-0 flex min-h-[500px] flex-col justify-between rounded bg-black px-4 py-8 text-white md:h-[700px] md:rounded-lg md:px-6 md:py-[60px]">
          <div className="absolute bottom-5 left-0 z-0 h-1/2 w-full">
            <AspectRatioComponent aspectRatio={{ width: 508, height: 423 }}>
              <Image
                unoptimized
                src={subscribeCard?.background.data[0]?.attributes.url}
                fill
                alt="Subscribe card background"
                className="absolute left-0 right-0 top-[160px]"
              />
            </AspectRatioComponent>
          </div>
          <div className="relative z-10 mt-10">
            <div className="absolute left-[80px] top-[-60px] h-[150px] w-[150px] animate-spin-slow md:left-[200px]">
              <AspectRatioComponent aspectRatio={{ width: 190, height: 190 }}>
                <Image
                  unoptimized
                  src="/images/pages/home/subscribe-logo.svg"
                  fill
                  alt="Subscribe"
                  className="max-w-[360px]"
                />
              </AspectRatioComponent>
            </div>
            <HeaderText
              inputString={subscribeCard.header[0].fullText}
              className="font-hero-title md:font-h2 relative max-w-[200px] bg-black md:max-w-[250px] "
            />
            <h5 className="font-h4 md:font-h5 mt-10 w-full md:w-[70%]">
              {subscribeCard.subheader}
            </h5>
          </div>
          <div className="relative z-10 w-full">
            <OutlineButton variant={"dark"}>
              <a
                href={subscribeCard.button[0].url || ""}
                className="font-btn-lg px-3"
              >
                {subscribeCard.button[0].cta}
              </a>
            </OutlineButton>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscribeCard;
