import React from "react";

const HeaderText = ({ inputString, className }) => {
  const parts = inputString.split(/\*\*(.*?)\*\*/g);

  return (
    <div className={className}>
      {parts.map((part, index) =>
        index % 2 === 1 ? (
          <div key={index} className="text-blue-light inline-block">
            {part}
          </div>
        ) : (
          part
        ),
      )}
    </div>
  );
};

export default HeaderText;
